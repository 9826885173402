.page-header {
  .header-wrapper {
    position: relative;
    height: 200px;
    @media screen and (max-width: 768px) {
      height: 150px;
    }
    overflow: hidden;
    background-color: rgba(#ff4d00, 0.2);
    border-radius: 0 0 20px 20px;
    @media screen and (max-width: 576px) {
      height: 150px;
      border-radius: 0 0;
    }

    .header-img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
    }

    .header-title {
      font-size: 2.5rem;
      color: #f5f5f5;
      font-weight: 700;
      position: absolute;
      bottom: 10px;
      right: 20px;
      text-transform: uppercase;
    }
  }
}