.title {
  font-weight: 700;
  position: relative;
  width: fit-content;
  text-align: center;
  margin-top: 3rem;
  margin-right: auto;
  margin-left: auto;

  &::before {
    content: '';
    position: absolute;
    height: 5px;
    width: 100%;
    background: #ff4d00;
    top: 100%;
    left: 0;
  }
}