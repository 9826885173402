footer {
  padding: 6rem 0;
  background: #FFD4C2;
  color: white;
  margin-top: 3rem;

  .logo-description {
    padding-bottom: 3rem;
    text-align: center;
    align-self: center;

    .logo-section {
      a img.footer-logo {
        padding-top: 10px;
        height: 60px;
      }
    }

    .description {
      color: #3c3b3b;
      padding-left: 10px;
    }
  }

  .social-links-wrapper {
    align-items: center;
    justify-content: space-evenly;

    ul li {
      display: block;
      a {
        display: block;
        text-align: center;
        color: #3c3b3b;
        &:hover {
          color: #ff4d00;
          text-decoration: underline;
        }
      }
    }
    .internal-links {
      ul li a {
        line-height: 2  ;
      }
    }
    .social-links {
      ul li a {
        font-size: 1.5rem;
      }
    }
  }
}