.history-section {
  padding: 8rem 0 5rem;
  div.wrapper {
    div.col-wrapper {
      align-self: flex-end;
      &.desc {
        padding-bottom: 1.5rem;
      }
      h2 {
        padding: 2rem 0 3.5rem 0;
        font-size: 2rem;
      }
      .button {
        margin-top: 1rem;
        padding: 1rem 2rem;
        background: #ff4d00;
        color: #fff;
        border-radius: 5px;
      }
      .image-wrapper {
        background: red;
        margin-top: -10px;
        position: relative;
        img {
          border-radius: 5px;
        }
        div.bg {
          height: 100%;
          width: 100%;background: #ff4d00;
          position: absolute;
          top: 20px;
          right: 20px;
          border-radius: 5px;
          z-index: -1;

          @media screen and(max-width: 768px) {
            width: 105%;
            left: -13px;
          }
        }
      }
    }
  }
}