.section-wrapper {
  padding-bottom: 3rem;
  .section-content {
    margin: 0;
    padding-top: 10rem;

    @media screen and(max-width: 768px) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .section-image-wrapper {
      position: relative;;
      margin-top: -4rem;
      padding: 0;
      height: fit-content;
      img {
        @media screen and(max-width: 768px) {
          width: 90%;
          margin-left: 5%;
          margin-right: 5%;
        }
      }
      .bg {
        height: 100%;
        width: 100%;background: #ff4d00;
        position: absolute;
        top: 20px;
        left: 20px;
        border-radius: 5px;
        z-index: -1;

        @media screen and(max-width: 768px) {
          width: 100%;
          left: 0;
        }
      }
    }
    .section-description-wrapper {
      padding-left: 2rem;
      @media screen and(max-width: 768px) {
        padding: 3rem 5px 0 5px;
      }
    }

    &.alt {
      @media screen and (min-width: 786px) {
        flex-direction: row-reverse;
      }

      .section-image-wrapper {
        .bg {
          left: auto;
          right: 20px;
          @media screen and(max-width: 768px) {
            right: 0;
          }
        }
      }

      .section-description-wrapper {
        text-align: right;
        padding-right: 3rem;
        @media screen and(max-width: 768px) {
          padding-right: 0.5rem;
          text-align: left;
        }
      }
    }
  }

  .team-details {
    padding-top: 3rem;
    padding-bottom: 3rem;
    .team-wrapper {
      padding: 10px;
      text-align: center;
      .team-wrapper__ind {
        padding-top: 2.5rem;
        &:not(last-child) {
          padding-bottom: 0.5rem;
        }

        .image-wrapper {
          width: 250px;
          height: 250px;
          @media screen and (max-width: 1200px) {
            width: 200px;
            height: 200px;
          }
          @media screen and (max-width: 768px) {
            width: 65vw;
            height: 65vw;
          }
          overflow: hidden;
          margin-right: auto;
          margin-left: auto;
          border-radius: 50%;
          border: 3px solid #ff4d00;

          img {
            width: 100%;
          }
        }
        .team-name {
          padding: 10px 0 0;
          font-size: 18px;
          font-weight: bold;
          color: #915a43;
          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
        }
        .team-post {
          font-size: 14px;
          color: #8f8f8f;
          font-weight: 700;

          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
      }
    }
  }
  &.contact-us {
    .contact-us-wrapper {
      @media screen and (max-width: 768px) {
        padding: 10px;
      }
      padding-top: 3rem;
      .contact-detail-wrapper {
        padding: 10px;

        img {

        }
        .contact-detail-section {
          padding-left: 1rem;
          margin-left: 0.6rem;
          border-left: #ff4d00 solid 3px;
          p {
            svg {
              color: #8a4320;
            }
            color: #666666;
          }
        }
      }
      .contact-form-wrapper {
        padding-top: 10px;
        @media screen and (max-width: 768px) {
          padding-top: 2rem;
        }

        .head {
          color: #cf4000;
          h3 {
            font-weight: 700;
          }
          p {
            color: black;
          }
        }

        .contactus-form{
          padding-right: 4rem;
          @media screen and (max-width: 992px) {
            padding-right: 0.5rem;
          }
          form {
            .input {
              label {
                padding: 3px 0;
                color: #8f8f8f;
              }
              input, textarea {
                width: 100%;
                padding: 10px 1rem;
                border-radius: 100rem;
                border: none;
                background: #d6d0cf;
                &:focus {
                  outline: none;
                }
              }
              textarea {
                border-radius: 20px;
              }

              input[type='submit'] {
                margin-top: 2rem;
                @media screen and (max-width: 768px) {
                  margin-top: 1rem;
                }
                width: auto;
                background: #ff4d00;
                color: white;
                border-radius: 10px;
                padding: 1rem 3rem;
                border: none;
                box-shadow: 5px 5px 5px rgba(#ff4d00, 0.2);
              }
            }
          }
        }
      }
    }
  }
}