.header {
  padding: 1rem 0;
  display: flex;

  &.row {
    margin: 0;
  }

  //align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }

  .hero-banner-content {
    margin-top: 20px;
    padding-bottom: 3rem;

    .hero-text {
      h1 {
        padding: 10px 10px 2rem 0;
        font-weight: 600;
      }

      h4 {
        padding: 0 0 2rem 0;
      }
    }

    .cta-btn {
      padding: 1rem 2rem;
      background: #ff4d00;
      font-size: 18px;
      color: white;
      font-weight: 400;
      border-radius: 5px;
    }
  }

  .hero-image-wrapper {
    padding: 10px;
    position: relative;
    //padding-top: 2rem;
    .hero-bg {
      position: absolute;
      height: 97%;
      width:97%;
      top: 20px;
      right: 25px;
      background-color: #ff4d00;
      z-index: -1;
      border-radius: 5px;
      @media screen and (max-width: 1200px){
        height: 93%;
        //right: 0;
      }
      @media screen and (max-width: 992px){
        width: 100%;
        height: 60%;
        right: 20px;
        top: 20px;
      }
      @media screen and (max-width: 768px){
        width: 100%;
        height: 97%;
        right: 0;
      }
    }

    img {
      border-radius: 5px;
      position: relative;
      //margin: 0 1rem;
      @media screen and(max-width: 756px) {
        margin: 0;
      }
    }

    .bg-2 {
      position: absolute;
      top: -207px;
      right: 0;
      z-index: -1;
      width: 724px;
      //@media screen and (max-width: 992px) {
      //  left: -211px;
      //}
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}