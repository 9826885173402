* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

ul {
  margin: 0;
}

body {
  position: relative;
  font-family: 'Poppins', sans-serif;
}