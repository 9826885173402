div.video-section {
  display: flex;
  align-items: center;
  padding-top: 8rem;

  .video-text {
    p {
      text-align: justify;
    }
    .annotate {
      text-align: right;
    }
  }
}