.my-navbar {
  background: rgba(black, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  height: 72px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo-wrapper {
      .logo {
        height: 1.5rem;
        @media screen and (max-width: 756px) {
          position: absolute;
          top: 21px;
          left: 50%;
          margin-left: -72px;
          height: 2rem;
        }
        @media screen and (max-width: 300px) {
          margin-left: -100px;
        }
      }
    }

    .navlinks {
      display: flex;
      padding: 0.5rem 0;

      &__navlink {
        display: block;
        padding: 1rem;
        @media screen and (max-width: 756px) {
          display: none;
        }

        &.menu-icon {
          display: none;
          font-size: 1.5rem;
          padding: 10px;
          @media screen and (max-width: 756px) {
            display: block;
          }

          &.menu-open {
            position: absolute;
            top: 0;
            right: 1rem;
            color: red;
            z-index: 999999;
            font-size: 2rem;
          }
        }

        a {
          color: black;
        }

        &:not(last-child) {
          padding-right: 1rem;
        }

        &.active {
          a {
            color: #ff4d00;
          }
        }

        &:hover {
          color: #ff4d00;
        }
      }
    }

    .mobile-menu {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      z-index: 99;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: rgba(black, 0.7);
      margin: 0;
      transition: ease-in-out 200ms;
      transform: translateX(-100%);

      &.open {
        transform: translateY(0);
      }
      .navlink {
        @media screen and (max-width: 756px) {
          display: block;
        }
        padding: 1rem;
        &.active a {
          color: #ff4d00;
        }
        a {
          font-size: 2rem;
          padding: 1rem 10px;
          color: white;
          text-transform: uppercase;
        }
      }
    }
  }
}